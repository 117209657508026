import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Send } from 'lucide-react';

const API_URL = 'https://api.chatinworld.com/CHATV2/groupchat.php';

const GroupChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [groupId, setGroupId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const pollingIntervalRef = useRef(null);

  useEffect(() => {
    initializeChat();
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const initializeChat = async () => {
    try {
      const response = await axios.get(`${API_URL}?action=init`);
      const { groupId } = response.data;
      setGroupId(groupId);
      await fetchMessages(groupId);
      startPolling(groupId);
      setIsLoading(false);
    } catch (error) {
      console.error('Error initializing chat:', error);
      setIsLoading(false);
    }
  };

  const startPolling = (gId) => {
    pollingIntervalRef.current = setInterval(() => {
      fetchMessages(gId);
    }, 5000); // Poll every 5 seconds
  };

  const fetchMessages = async (gId) => {
    try {
      const lastMessageId = messages.length > 0 ? messages[messages.length - 1].id : '';
      const response = await axios.get(`${API_URL}?action=get_messages&groupId=${gId}&lastMessageId=${lastMessageId}`);
      const newMessages = response.data.messages;
      if (newMessages.length > 0) {
        setMessages(prevMessages => [...prevMessages, ...newMessages]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = {
      sender: 'user',
      content: input,
    };

    try {
      await axios.post(`${API_URL}?action=send`, {
        groupId,
        sender: 'user',
        message: input,
      });
      setInput('');
      // The new messages (including bot responses) will be fetched by the polling mechanism
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="bg-green-600 text-white p-4">
        <h1 className="text-xl font-bold">Groupe de Chat IA</h1>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((message) => (
          <div key={message.id} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
            {message.sender !== 'user' && (
              <img 
                src={`https://api.chatinworld.com/CHATV2/chatbots/${message.sender}/profile_thumb.jpg`} 
                alt={message.sender} 
                className="w-8 h-8 rounded-full mr-2"
              />
            )}
            <div className={`rounded-lg p-2 max-w-xs lg:max-w-md ${message.sender === 'user' ? 'bg-green-500 text-white' : 'bg-white'}`}>
              {message.sender !== 'user' && (
                <p className="font-bold text-sm">{message.sender}</p>
              )}
              <p>{message.content}</p>
              <p className="text-xs text-gray-500 mt-1">
                {new Date(message.timestamp * 1000).toLocaleTimeString()}
              </p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={sendMessage} className="bg-white p-4 flex">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Tapez un message..."
          className="flex-1 border rounded-full py-2 px-4 mr-2 focus:outline-none focus:border-green-500"
        />
        <button type="submit" className="bg-green-500 text-white rounded-full p-2">
          <Send size={24} />
        </button>
      </form>
    </div>
  );
};

export default GroupChat;
