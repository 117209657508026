import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Capacitor } from '@capacitor/core';
import { Purchases } from '@revenuecat/purchases-capacitor';


// Supprimez complètement cette ligne
// Capacitor.initialize();

// Vous pouvez ajouter ce code pour vérifier que Capacitor est correctement importé
console.log('Capacitor Platform:', Capacitor.getPlatform());

// Enregistrer le service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

document.addEventListener('deviceready', initializeStore, false);

function initializeStore() {
  if (Capacitor.isNativePlatform()) {
    if (window.CdvPurchase && window.CdvPurchase.store) {
      window.store = window.CdvPurchase.store;
      // Initialisation du store ici
      window.store.verbosity = window.store.DEBUG;
      // Autres configurations...
    } else {
      console.error('CdvPurchase plugin not available');
    }
  }
}

const initializeRevenueCat = async () => {
  if (Capacitor.isNativePlatform()) {
    await Purchases.configure({ apiKey: 'sk_SaivYgRPTdMRPQYHusmkDLusaFviS' });
  }
};

initializeRevenueCat();



const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);