import React, { useState, useEffect } from 'react';
import { Eye, Check } from 'lucide-react';

const MessageStatus = ({ status }) => {
  if (status === 'sent') {
    return <Check size={14} className="text-gray-400" />;
  } else if (status === 'delivered') {
    return (
      <div className="flex items-center">
        <Check size={14} className="text-gray-400" />
        <Check size={14} className="text-gray-400 -ml-2" />
      </div>
    );
  } else if (status === 'read') {
    return (
      <div className="flex items-center">
        <Check size={14} className="text-blue-500" />
        <Check size={14} className="text-blue-500 -ml-2" />
      </div>
    );
  }
  return null;
};

const Message = ({ message, onUnblurImage }) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [isBlurSupported, setIsBlurSupported] = useState(true);
  
  useEffect(() => {
    // Vérifier si le floutage est supporté
    const testBlur = () => {
      const el = document.createElement('div');
      el.style.cssText = 'filter: blur(5px)';
      return el.style.length > 0;
    };
    setIsBlurSupported(testBlur());

    if (message.photo) {
      if (message.role === 'user') {
        // Les photos de l'utilisateur ne sont jamais floutées
        setIsBlurred(false);
      } else {
        // Pour les photos du chatbot, vérifier si elles sont déverrouillées
        const unlockedImages = JSON.parse(localStorage.getItem('unlockedImages') || '{}');
        setIsBlurred(!unlockedImages[message.photo]);
      }
    }
  }, [message.photo, message.role]);

  const handleImageClick = () => {
    if (isBlurred) {
      onUnblurImage(message.photo);
    }
  };

  return (
    <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-2`}>
      <div className={`rounded-lg p-2 max-w-xs lg:max-w-md ${message.role === 'user' ? 'bg-[#dcf8c6]' : 'bg-white'}`}>
        {message.photo ? (
          <div className="relative" onClick={handleImageClick}>
            {isBlurred ? (
              isBlurSupported ? (
                <img 
                  src={message.photo} 
                  alt={message.role === 'user' ? "Sent by user" : "Sent by chatbot"}
                  className="mt-1 rounded-lg blur-md"
                  style={{ filter: 'blur(20px)' }}
                />
              ) : (
                <div className="mt-1 rounded-lg bg-gray-200 h-48"></div>
              )
            ) : (
              <img 
                src={message.photo} 
                alt={message.role === 'user' ? "Sent by user" : "Sent by chatbot"}
                className="mt-1 rounded-lg"
              />
            )}
            {isBlurred && (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white">
                <Eye size={48} />
                <p className="mt-2 text-center">Cliquez ici pour afficher l'image</p>
              </div>
            )}
            {message.uploadProgress !== undefined && message.uploadProgress < 100 && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
                <span className="absolute text-white">{message.uploadProgress}%</span>
              </div>
            )}
          </div>
        ) : (
          <p className="mr-2">{message.content}</p>
        )}
        <div className="flex items-center text-xs text-gray-500 mt-1">
          <span>{message.time}</span>
          {message.role === 'user' && <MessageStatus status={message.status} />}
        </div>
      </div>
    </div>
  );
};

export default Message;
