import React, { useState, useEffect } from 'react';
import { Purchases } from '@revenuecat/purchases-capacitor';

const InAppPurchaseModal = ({ isOpen, onClose, onPurchaseComplete, productId }) => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchOfferings();
    }
  }, [isOpen]);

  const fetchOfferings = async () => {
    try {
      const offerings = await Purchases.getOfferings();
      if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
        setPackages(offerings.current.availablePackages);
      }
    } catch (e) {
      console.error('Error fetching offerings', e);
    }
  };

  const handlePurchase = async (packageToPurchase) => {
    try {
      const { customerInfo, productIdentifier } = await Purchases.purchasePackage({ aPackage: packageToPurchase });
      console.log('Purchase successful', customerInfo);
      onPurchaseComplete(productIdentifier);
    } catch (e) {
      if (!e.userCancelled) {
        console.error('Error purchasing package', e);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <h2>Débloquer le contenu</h2>
      {packages.map((pkg, index) => (
        <button key={index} onClick={() => handlePurchase(pkg)}>
          Acheter {pkg.product.priceString}
        </button>
      ))}
      <button onClick={onClose}>Fermer</button>
    </div>
  );
};

export default InAppPurchaseModal;
