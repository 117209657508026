import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Send, Camera, Check, MoreVertical, X, Search, ArrowLeft } from 'lucide-react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Message from './Message';
import SMSPurchaseModal from './SMSPurchaseModal';
import MessageLimitModal from './MessageLimitModal';
import InstallPWA from './InstallPWA';
import GroupChat from './GroupChat';
import InAppPurchaseModal from './InAppPurchaseModal';
import { Capacitor } from '@capacitor/core';




// Nombre de messages inclus avant de demander un déblocage
const FREE_MESSAGE_LIMIT = 15;

const MessageStatus = ({ status }) => {
  if (status === 'sent') {
    return <Check size={14} className="text-gray-400" />;
  } else if (status === 'delivered') {
    return (
      <div className="flex items-center">
        <Check size={14} className="text-gray-400" />
        <Check size={14} className="text-gray-400 -ml-2" />
      </div>
    );
  } else if (status === 'read') {
    return (
      <div className="flex items-center">
        <Check size={14} className="text-blue-500" />
        <Check size={14} className="text-blue-500 -ml-2" />
      </div>
    );
  }
  return null;
};

const TypingIndicator = () => (
  <div className="flex items-center space-x-1 p-2 bg-white rounded-lg shadow">
    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '200ms' }}></div>
    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '400ms' }}></div>
  </div>
);

const ProfilePicModal = ({ src, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="relative">
      <img src={src} alt="Profile" className="max-w-full max-h-[90vh] rounded-lg" />
      <button onClick={onClose} className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full p-1">
        <X size={24} />
      </button>
    </div>
  </div>
);

const LoginForm = ({ onLogin, error }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(email, password);
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-white rounded-lg shadow-md">
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
        className="w-full p-2 mb-2 border rounded"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Mot de passe"
        required
        className="w-full p-2 mb-2 border rounded"
      />
      <button type="submit" className="w-full p-2 bg-green-500 text-white rounded">Se connecter</button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </form>
  );
};

function ChatbotList() {
  const [chatbots, setChatbots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const navigate = useNavigate();

  useEffect(() => {
    fetchChatbots();
    // fetchUserData();

    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const fetchChatbots = async () => {
    try {
      const response = await axios.get('https://api.chatinworld.com/CHATV2/api_chatbots.php');
      const currentTime = new Date().getTime();
      
      const chatbotsWithLastMessage = response.data.map((chatbot) => {
        const lastMessage = fetchLastMessage(chatbot.name);
        const messageTime = lastMessage.time ? new Date(lastMessage.time).getTime() : null;
        const connectionTime = new Date(`1970-01-01T${chatbot.lastConnection}`).getTime();
        
        return {
          ...chatbot,
          lastMessage: lastMessage.message,
          lastMessageTime: lastMessage.time,
          sortTime: messageTime || connectionTime,
          timeDifference: currentTime - (messageTime || connectionTime)
        };
      });
  
      // Tri des chatbots
      const sortedChatbots = chatbotsWithLastMessage.sort((a, b) => {
        // Si les deux ont des messages, comparez leurs différences de temps (ordre inverse)
        if (a.lastMessageTime && b.lastMessageTime) {
          return b.timeDifference - a.timeDifference;
        }
        // Si seulement a a un message, a vient en premier
        if (a.lastMessageTime && !b.lastMessageTime) return -1;
        // Si seulement b a un message, b vient en premier
        if (!a.lastMessageTime && b.lastMessageTime) return 1;
        // Si aucun n'a de message, comparez leurs temps de dernière connexion
        return a.timeDifference - b.timeDifference;
      });
  
      setChatbots(sortedChatbots);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching chatbots:', error);
      setIsLoading(false);
    }
  };
  
  const fetchLastMessage = (chatbotName) => {
    const storedMessages = localStorage.getItem(`chatMessages_${chatbotName}`);
    if (storedMessages) {
      const messages = JSON.parse(storedMessages);
      if (messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        return {
          message: lastMessage.content || (lastMessage.photo ? "[Photo]" : ""),
          time: lastMessage.time
        };
      }
    }
    return { message: '', time: null };
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://api.chatinworld.com/CHATV2/api_user.php');
      if (response.data.isLoggedIn) {
        setIsLoggedIn(true);
        setUserData(response.data.userData);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleChatbotClick = (chatbotName) => {
    navigate(`/chat/${chatbotName}`);
  };

  const filteredChatbots = chatbots.filter(chatbot =>
    chatbot.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatTime = (timeString) => {
    if (!timeString) return '';
    
    // Si c'est déjà au format HH:MM, on le retourne tel quel
    if (/^\d{2}:\d{2}$/.test(timeString)) {
      return timeString;
    }
    
    // Sinon, on essaie de le parser comme une date
    const date = new Date(timeString);
    if (isNaN(date.getTime())) {
      return ''; // Retourne une chaîne vide si la date est invalide
    }
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  
  return (
    <div 
      className="flex flex-col bg-cover bg-center bg-no-repeat" 
      style={{ 
        backgroundImage: "url('/images/background.jpg')",
        height: `${viewportHeight}px`
      }}
    >
      <div className="bg-green-600 text-white p-4 flex items-center justify-between shadow-md z-10">
        <h1 className="text-xl font-bold">Chat</h1>
        <div className="flex items-center">
          <InstallPWA />
        <div className="relative">
          <button onClick={handleMenuClick} className="p-2">
            <MoreVertical size={24} />
          </button>
          {showMenu && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
              {isLoggedIn ? (
                <>
                  <a href="/logout" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Se déconnecter</a>
                  {userData && (
                    <div className="block px-4 py-2 text-sm text-gray-700">
                      Tokens: {userData.tokens}
                    </div>
                  )}
                </>
              ) : (
                <a href="/login" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Se connecter</a>
              )}
            </div>
          )}
        </div>
        </div>
      </div>
      
      <div className="p-4 bg-gray-200 bg-opacity-75">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher un utilisateur "
            className="w-full p-2 pl-10 rounded-full bg-white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2 text-gray-400" size={20} />
        </div>
      </div>

      <div className="flex-1 overflow-y-auto bg-white bg-opacity-75">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
          </div>
        ) : (
          filteredChatbots.map((chatbot) => (
            <div 
              key={chatbot.name} 
              className="flex items-center p-4 hover:bg-gray-100 border-b border-gray-200 cursor-pointer"
              onClick={() => handleChatbotClick(chatbot.name)}
            >
              <img
                src={chatbot.profilePicture}
                alt={chatbot.name}
                className="w-12 h-12 rounded-full mr-4 object-cover"
                onError={(e) => { e.target.onerror = null; e.target.src = 'default-profile.jpg'; }}
              />
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <h2 className="font-semibold">{chatbot.name}</h2>
                  <span className="text-xs text-gray-500">
                    {formatTime(chatbot.lastMessageTime) || formatTime(chatbot.lastConnection) || 'Pas de date'}
                  </span>
                </div>
                <p className="text-sm text-gray-500 truncate">
                  {chatbot.lastMessage || `En ligne`}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}


function Chat() {
  const { chatbotName } = useParams();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [city, setCity] = useState('Paris');
  const [isLoading, setIsLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [userid, setUserid] = useState('');
  const [connected, setConnected] = useState(false);
  const [chatbot, setChatbot] = useState(chatbotName);
  const [sessionId, setSessionId] = useState('');
  const [chatbotProfilePic, setChatbotProfilePic] = useState('');

  const photoInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProfilePic, setShowProfilePic] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loginError, setLoginError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);

  const [unlockedImages, setUnlockedImages] = useState({});
  const [showSMSPurchaseModal, setShowSMSPurchaseModal] = useState(false);
  const [showInAppPurchaseModal, setShowInAppPurchaseModal] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);

  const [messageCount, setMessageCount] = useState(parseInt(localStorage.getItem('messageCount') || '0'));
  const [showMessageLimitModal, setShowMessageLimitModal] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedSessionId = localStorage.getItem(`sessionId_${chatbotName}`);
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      localStorage.setItem(`sessionId_${chatbotName}`, newSessionId);
    }

    const storedMessages = localStorage.getItem(`chatMessages_${chatbotName}`);
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }

    const storedUnlockedImages = JSON.parse(localStorage.getItem('unlockedImages') || '{}');
    setUnlockedImages(storedUnlockedImages);

    setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    const storedMessageCount = parseInt(localStorage.getItem('messageCount') || '0');
    if (storedMessageCount >= FREE_MESSAGE_LIMIT) {
      setIsLimitReached(true);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [chatbotName]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  useEffect(() => {
    const profilePicUrl = `https://api.chatinworld.com/CHATV2/chatbots/${chatbot}/profile_thumb.jpg`;
    setChatbotProfilePic(profilePicUrl);
  }, [chatbot]);

  useEffect(() => {
    localStorage.setItem('messageCount', messageCount.toString());
    if (messageCount >= FREE_MESSAGE_LIMIT && !isLimitReached) {
      setIsLimitReached(true);
      setShowMessageLimitModal(true);
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          role: 'system', 
          content: "Vous avez atteint la limite de messages gratuits. Veuillez débloquer plus de messages pour continuer.",
          time: getCurrentTime()
        }
      ]);
    }
  }, [messageCount, isLimitReached]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMessageStatus = (index, status) => {
    setMessages(prevMessages => {
      const newMessages = prevMessages.map((msg, i) => 
        i === index ? { ...msg, status } : msg
      );
      localStorage.setItem(`chatMessages_${chatbotName}`, JSON.stringify(newMessages));
      return newMessages;
    });
  };

  const getCurrentTime = () => {
    const now = new Date();
    return `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
  };

  const simulateTyping = async (delay) => {
    setIsTyping(true);
    await new Promise(resolve => setTimeout(resolve, delay));
    setIsTyping(false);
  };

  const handleInputClick = () => {
    if (isLimitReached) {
      setShowMessageLimitModal(true);
    }
  };

  const handleCameraClick = () => {
    if (isLimitReached) {
      setShowMessageLimitModal(true);
    } else if (photoInputRef.current) {
      photoInputRef.current.click();
    }
  };

  const handlePhotoCapture = async (event) => {
    if (isLimitReached) {
      setShowMessageLimitModal(true);
      return;
    }
    const file = event.target.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64data = reader.result;
          
          const newPhotoMessageIndex = messages.length;
          setMessages(prevMessages => {
            const newMessages = [...prevMessages, 
              { role: 'user', content: '', photo: base64data, time: getCurrentTime(), status: 'sending', uploadProgress: 0 }
            ];
            localStorage.setItem(`chatMessages_${chatbotName}`, JSON.stringify(newMessages));
            return newMessages;
          });

          setMessageCount(prevCount => prevCount + 1);

          let progress = 0;
          const intervalId = setInterval(() => {
            progress += 10;
            setMessages(prevMessages => {
              const newMessages = prevMessages.map((msg, index) => 
                index === newPhotoMessageIndex ? { ...msg, uploadProgress: progress } : msg
              );
              return newMessages;
            });
            if (progress >= 100) {
              clearInterval(intervalId);
            }
          }, 200);

          const response = await fetch('https://api.chatinworld.com/CHATV2/api_analyze.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ image: base64data }),
          });

          clearInterval(intervalId);
          setMessages(prevMessages => {
            const newMessages = prevMessages.map((msg, index) => 
              index === newPhotoMessageIndex ? { ...msg, uploadProgress: 100 } : msg
            );
            return newMessages;
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          if (data.success) {
            updateMessageStatus(newPhotoMessageIndex, 'read');
            handleSubmit(null, data.message, true);
          } else {
            console.error("Erreur lors de l'analyse de l'image:", data.error);
            setMessages(prevMessages => {
              const newMessages = [...prevMessages, 
                { role: 'assistant', content: "Désolé, je n'ai pas pu analyser cette image.", time: getCurrentTime() }
              ];
              localStorage.setItem(`chatMessages_${chatbotName}`, JSON.stringify(newMessages));
              return newMessages;
            });
          }
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Erreur lors de l'envoi de la photo:", error);
        setMessages(prevMessages => {
          const newMessages = [...prevMessages, 
            { role: 'assistant', content: "Une erreur s'est produite lors de l'analyse de l'image.", time: getCurrentTime() }
          ];
          localStorage.setItem(`chatMessages_${chatbotName}`, JSON.stringify(newMessages));
          return newMessages;
        });
      }
    }
  };

  const handleSubmit = async (e, customMessage = null, hideMessage = false) => {
    if (e) e.preventDefault();
    
    if (isLimitReached) {
      setShowMessageLimitModal(true);
      return;
    }

    const messageContent = customMessage || input;
    if (!messageContent.trim()) return;

    console.log("Envoi de la requête avec input:", messageContent);

    if (!hideMessage) {
      setMessageCount(prevCount => prevCount + 1);
    }

    let newMessageIndex;
    if (!hideMessage) {
      newMessageIndex = messages.length;
      setMessages(prevMessages => {
        const newMessages = [...prevMessages, { role: 'user', content: messageContent, status: 'sending', time: getCurrentTime() }];
        localStorage.setItem(`chatMessages_${chatbotName}`, JSON.stringify(newMessages));
        return newMessages;
      });
      setInput('');
      if (inputRef.current) inputRef.current.blur();

      setTimeout(() => updateMessageStatus(newMessageIndex, 'sent'), 500);
      setTimeout(() => updateMessageStatus(newMessageIndex, 'delivered'), 1500);
    }
    
    try {
      const formData = new FormData();
      formData.append('input', messageContent);
      formData.append('city', localStorage.getItem('city') || city);
      formData.append('userid', userid || 'anonymous');
      formData.append('connected', connected ? '1' : '0');
      formData.append('chatbot', chatbot);
      formData.append('sessionId', sessionId);
      formData.append('clickid', localStorage.getItem('clickid') || null);

      if (!hideMessage) {
        await new Promise(resolve => setTimeout(resolve, 3000));
        updateMessageStatus(newMessageIndex, 'read');
      }

      const readingDelay = Math.min(messageContent.length * 80, 2000);
      await new Promise(resolve => setTimeout(resolve, readingDelay));

      const response = await fetch('https://api.chatinworld.com/CHATV2/api.php', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSessionId(data.sessionId);

      const typingDelay = data.photo ? 10000 : Math.min(data.message.length * 100, 30000);
      await simulateTyping(typingDelay);

      setMessages(prevMessages => {
        const newMessages = [...prevMessages, { role: 'assistant', content: data.message, photo: data.photo, time: getCurrentTime() }];
        localStorage.setItem(`chatMessages_${chatbotName}`, JSON.stringify(newMessages));
        return newMessages;
      });

    } catch (error) {
      console.error('Erreur détaillée:', error);
      setIsTyping(false);
      setMessages(prevMessages => {
        const newMessages = [...prevMessages, { role: 'assistant', content: "Je suis désolé, une erreur s'est produite. Pouvez-vous réessayer?", time: getCurrentTime() }];
        localStorage.setItem(`chatMessages_${chatbotName}`, JSON.stringify(newMessages));
        return newMessages;
      });
    }
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await fetch('https://api.chatinworld.com/CHATV2/api_login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.success) {
        setIsLoggedIn(true);
        setUserData(data.userData);
        setUserid(data.userData.userid);
        setConnected(true);
        setLoginError('');
        setShowLoginForm(false);
      } else {
        setLoginError(data.message);
      }
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setLoginError("Une erreur s'est produite lors de la connexion.");
    }
  };

  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  const handleUnblurImage = (imageId) => {
    setCurrentProductId(imageId);
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
      setShowInAppPurchaseModal(true);
    } else {
      setShowSMSPurchaseModal(true);
    }
  };

  const handlePurchaseComplete = (productId) => {
    const updatedUnlockedImages = { ...unlockedImages, [productId]: true };
    setUnlockedImages(updatedUnlockedImages);
    localStorage.setItem('unlockedImages', JSON.stringify(updatedUnlockedImages));
    setShowSMSPurchaseModal(false);
    setShowInAppPurchaseModal(false);

    setMessages(prevMessages => 
      prevMessages.map(msg => 
        msg.photo === productId ? { ...msg, isBlurred: false } : msg
      )
    );
  };

  const handleUnlockComplete = () => {
    setShowMessageLimitModal(false);
    setMessageCount(0);
    setIsLimitReached(false);
    localStorage.setItem('messageCount', '0');
  };

  return (
    <div className="flex flex-col bg-cover bg-center bg-no-repeat"
         style={{
           backgroundImage: "url('/images/background.jpg')",
           height: `${viewportHeight}px`
         }}>
      {showLoginForm ? (
        <div className="flex items-center justify-center h-full">
          <LoginForm onLogin={handleLogin} error={loginError} />
          <button onClick={toggleLoginForm} className="mt-4 text-blue-500">
            Retour au chat
          </button>
        </div>
      ) : (
        <>
                    <div className="bg-white text-black p-4 flex items-center justify-between shadow-md z-10">
            <div className="flex items-center">
              <button onClick={() => navigate('/')} className="mr-2">
                <ArrowLeft size={24} />
              </button>
              <img 
                src={chatbotProfilePic} 
                alt={`${chatbot} profile`} 
                className="w-10 h-10 rounded-full mr-3 cursor-pointer"
                onClick={() => setShowProfilePic(true)}
                onError={(e) => {
                  e.target.onerror = null; 
                  e.target.src = 'https://api.chatinworld.com/CHATV2/default-profile.jpg';
                }}
              />
              <div>
                <h1 className="text-xl font-bold">{chatbot}</h1>
                <p className="text-sm text-gray-600">
                  {isTyping ? "Est en train d'écrire..." : "En ligne"}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <InstallPWA /> 
              <div className="relative">
                <button onClick={() => setShowMenu(!showMenu)} className="p-2">
                  <MoreVertical size={24} />
                </button>
                {showMenu && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    {isLoggedIn ? (
                      <>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Se déconnecter</a>
                        {userData && (
                          <div className="block px-4 py-2 text-sm text-gray-700">
                            Tokens: {userData.tokens}
                          </div>
                        )}
                      </>
                    ) : (
                      <a href="#" onClick={toggleLoginForm} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Se connecter</a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            {messages.map((message, index) => (
              <Message
                key={index}
                message={message}
                onUnblurImage={handleUnblurImage}
              />
            ))}
            {isTyping && (
              <div className="flex justify-start mb-2">
                <div className="bg-white rounded-lg p-2 flex items-center space-x-1">
                  <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
                  <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
                  <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={handleSubmit} className="p-4 flex bg-transparent shadow-md">
            <input
              ref={inputRef}
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onClick={handleInputClick}
              placeholder={isLimitReached ? "Limite de messages atteinte" : "Tapez un message"}
              className="flex-1 rounded-full py-2 px-4 mr-2 focus:outline-none bg-white cursor-pointer"
              readOnly={isLimitReached}
            />
            <input
              type="file"
              ref={photoInputRef}
              accept="image/*"
              capture="user"
              onChange={handlePhotoCapture}
              style={{ display: 'none' }}
              disabled={isLimitReached}
            />
            <button 
              type="button" 
              onClick={handleCameraClick} 
              className={`p-2 rounded-full ${isLimitReached ? 'bg-gray-400' : 'bg-gray-200'} mr-2`}
            >
              <Camera size={20} />
            </button>
            <button 
              type="submit" 
              className={`p-2 rounded-full ${isLimitReached ? 'bg-gray-400' : 'bg-green-500'} text-white`}
            >
              <Send size={20} />
            </button>
          </form>
          {showProfilePic && (
            <ProfilePicModal
              src={chatbotProfilePic}
              onClose={() => setShowProfilePic(false)}
            />
          )}
        </>
      )}
      <SMSPurchaseModal
        isOpen={showSMSPurchaseModal}
        onClose={() => setShowSMSPurchaseModal(false)}
        onPurchaseComplete={handlePurchaseComplete}
        imageId={currentProductId}
      />
      <InAppPurchaseModal
        isOpen={showInAppPurchaseModal}
        onClose={() => setShowInAppPurchaseModal(false)}
        onPurchaseComplete={handlePurchaseComplete}
        productId={currentProductId}
      />
      <MessageLimitModal
        isOpen={showMessageLimitModal}
        onClose={() => setShowMessageLimitModal(false)}
        onUnlockComplete={handleUnlockComplete}
      />
    </div>
  );
}







function App() {
  const location = useLocation();
  const [paymentSystem, setPaymentSystem] = useState('sms'); // Default to SMS


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const city = params.get('city');
    const clickid = params.get('clickid');
    const payment = params.get('payment');
    
    if (city) {
      localStorage.setItem('city', city);
    }

    if (clickid) {
      localStorage.setItem('clickid', clickid);
    }

    if (payment === 'revenuecat') {
      setPaymentSystem('revenuecat');
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<ChatbotList />} />
      <Route path="/chat/:chatbotName" element={<Chat />} />
      <Route path="/groupchat" element={<GroupChat />} />
    </Routes>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;

