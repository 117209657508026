import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Loader, X } from 'lucide-react';
import { Capacitor } from '@capacitor/core';

const sendSMS = async (number, message) => {
  if (Capacitor.isNativePlatform()) {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Envoyer un SMS',
          text: message,
          url: `sms:${number}?body=${encodeURIComponent(message)}`
        });
      } else {
        window.open(`sms:${number}?body=${encodeURIComponent(message)}`, '_self');
      }
      return true;
    } catch (error) {
      console.error('Error sending SMS:', error);
      return false;
    }
  } else {
    window.open(`sms:${number}?body=${encodeURIComponent(message)}`, '_self');
    return true;
  }
};

const MessageLimitModal = ({ isOpen, onClose, onUnlockComplete }) => {
  const [purchaseCode, setPurchaseCode] = useState('');
  const [status, setStatus] = useState('loading');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      generatePurchaseCode();
    }
  }, [isOpen]);

  const generatePurchaseCode = async () => {
    try {
      setStatus('loading');
      const clickid = localStorage.getItem('clickid') || '';
      console.log('Generating purchase code for message unlock, clickid:', clickid);
      const response = await axios.get('https://api.chatinworld.com/CHATV2/generate_image_purchase_code.php', {
        params: { imageId: 'message_unlock', clickid: clickid }
      });
      console.log('Server response:', response.data);
      if (response.data && response.data.code) {
        setPurchaseCode(response.data.code);
        setStatus('initial');
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      console.error('Error generating purchase code:', error);
      setErrorMessage("Erreur lors de la génération du code.");
      setStatus('error');
    }
  };

  const handleSendSMS = async (e) => {
    e.preventDefault();
    const success = await sendSMS('81183', `CHAT${purchaseCode}`);
    if (success) {
      setStatus('verifying');
      checkPurchaseStatus();
    } else {
      setErrorMessage("Erreur lors de l'envoi du SMS. Veuillez réessayer.");
      setStatus('error');
    }
  };

  const checkPurchaseStatus = async () => {
    let attempts = 0;
    const maxAttempts = 24; // 2 minutes (24 * 5 secondes)
    const clickid = localStorage.getItem('clickid') || '';

    const checkStatus = async () => {
      try {
        console.log('Checking purchase status for code:', purchaseCode, 'imageId: message_unlock', 'clickid:', clickid);
        const response = await axios.post('https://api.chatinworld.com/CHATV2/check_image_purchase_status.php', {
          code: purchaseCode,
          imageId: 'message_unlock',
          clickid: clickid
        });
        console.log('Server response:', response.data);
        if (response.data.status) {
          setStatus('success');
          onUnlockComplete();
          setTimeout(() => {
            onClose();
          }, 1500);
        } else if (attempts >= maxAttempts) {
          setStatus('timeout');
        } else {
          attempts++;
          setTimeout(checkStatus, 5000);
        }
      } catch (error) {
        console.error('Error checking purchase status:', error);
        setStatus('error');
        setErrorMessage("Erreur lors de la vérification du statut d'achat.");
      }
    };

    checkStatus();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-sm w-full relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <div className="flex flex-col items-center text-center">
          <h2 className="text-xl font-bold mb-4">Limite de messages atteinte</h2>
          {status === 'loading' && (
            <Loader className="animate-spin text-blue-500 mb-4" size={32} />
          )}
          {status === 'initial' && (
            <>
              <div className="mb-4">
                <p>Vous avez atteint la limite de messages .</p>
                <p>Pour continuer à profiter de notre service de chat de qualité et 15 messages supplémentaires, une vérification par SMS est nécessaire.</p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <p className="font-semibold mb-2">Envoyez ce code par SMS au 81183 :</p>
                <p className="text-3xl font-bold text-blue-600">CHAT{purchaseCode}</p>
              </div>
              
              <button 
                onClick={handleSendSMS}
                className="block w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 text-center"
              >
                Valider le paiement
              </button>
              <p className="text-[10px] mt-2 text-gray-500">
                4,50 EURO par SMS + prix d'un SMS
              </p>
              <p className="text-[8px] mt-2 text-gray-500">
                Le service de déblocage de messages par SMS permet à tous nos utilisateurs de profiter de cette option sans surprise. Pour seulement 4,5€ par SMS + coût du SMS selon opérateur, vous avez la possibilité de continuer à utiliser notre service de chat de qualité supérieure sans interruption.
                Service client : 0975182032 ou ath.fr@mob.support athdigital RCS825 403 918
              </p>
              
             
            </>
          )}
          {status === 'verifying' && (
            <>
              <p className="mb-4">Vérification du SMS en cours</p>
              <Loader className="animate-spin text-blue-500" size={32} />
            </>
          )}
          {status === 'success' && <p>SMS confirmé ! Vous pouvez continuer à chatter.</p>}
          {status === 'timeout' && <p>Le délai de vérification est écoulé. Veuillez réessayer.</p>}
          {status === 'error' && <p>{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default MessageLimitModal;